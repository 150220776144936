import { SKIN_COLOR, HAIR_COLOR } from '@/modules/questionnaire/api/constants';

const SKIN_COLOR_COMMON_VALUES = [
  SKIN_COLOR.WHITE,
  SKIN_COLOR.LIGHT_BROWN,
  SKIN_COLOR.MEDIUM_BROWN,
  SKIN_COLOR.DARK_BROWN
];

const SKIN_COLOR_VERY_DARK_VALUES = [SKIN_COLOR.BLACK];

const SKIN_COLOR_VERY_LIGHT_VALUES = [SKIN_COLOR.VERY_PALE_WHITE, SKIN_COLOR.PALE_WHITE];

export const SKIN_COLOR_WITH_MIDDLE_HAIR_VALUES = [
  ...SKIN_COLOR_COMMON_VALUES,
  ...SKIN_COLOR_VERY_LIGHT_VALUES,
  ...SKIN_COLOR_VERY_DARK_VALUES
];

export const SKIN_COLOR_WITH_LIGHT_HAIR_VALUES = [
  ...SKIN_COLOR_COMMON_VALUES,
  ...SKIN_COLOR_VERY_LIGHT_VALUES
];

export const SKIN_COLOR_WITH_DARK_HAIR_VALUES = [
  ...SKIN_COLOR_COMMON_VALUES,
  ...SKIN_COLOR_VERY_DARK_VALUES
];

export const LIGHT_HAIR_COLOR = [HAIR_COLOR.RED, HAIR_COLOR.LIGHT_BLONDE, HAIR_COLOR.MEDIUM_BLONDE];

export const DARK_HAIR_COLOR = [HAIR_COLOR.MEDIUM_BROWN, HAIR_COLOR.DARK_BROWN, HAIR_COLOR.BLACK];
